var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Topbar'),_c('div',{staticClass:"footer-content"},[_c('h5',[_vm._v(" "+_vm._s(_vm.route.index == 0 ? "公司介绍" : _vm.route.index == 1 ? "公司地址" : _vm.route.index == 2 ? "法律条款" : _vm.route.index == 3 ? "免责声明" : "")+" ")]),_c('div',{staticClass:"footer-font",domProps:{"innerHTML":_vm._s(
        _vm.route.index == 0
          ? _vm.foots.CompanyIntroduce
          : _vm.route.index == 1
          ? _vm.foots.CompanyAddress
          : _vm.route.index == 2
          ? _vm.foots.LegalProvisions
          : _vm.route.index == 3
          ? _vm.foots.Disclaimers
          : ''
      )}})]),_c('Foter')],1)}
var staticRenderFns = []

export { render, staticRenderFns }