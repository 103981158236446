<template>
  <div>
    <Topbar></Topbar>
    <div class="footer-content">
      <h5>
        {{
          route.index == 0
            ? "公司介绍"
            : route.index == 1
            ? "公司地址"
            : route.index == 2
            ? "法律条款"
            : route.index == 3
            ? "免责声明"
            : ""
        }}
      </h5>
      <div
        class="footer-font"
        v-html="
          route.index == 0
            ? foots.CompanyIntroduce
            : route.index == 1
            ? foots.CompanyAddress
            : route.index == 2
            ? foots.LegalProvisions
            : route.index == 3
            ? foots.Disclaimers
            : ''
        "
      ></div>
    </div>
    <Foter></Foter>
  </div>
</template>
<script>
import Topbar from '../../PCshop/components/topbar.vue'
import Foter from '../../PCshop/components/shopfooter.vue'
import { queryCompanyIntroduce } from "@/api/shop";
export default {
  components: {
    Topbar, Foter
  },
  data () {
    return {
      route: {},
      foots: {}
    }
  },
  watch: {
    $route: {
      handler () {
        this.route = this.$route.query;
        document.title = this.route.text;
        this.getQueryCompanyIntroduce();
        //深度监听，同时也可监听到param参数变化
      },
      deep: true,
    }
  },
  mounted () {
    this.route = this.$route.query
    document.title = this.route.text;
    console.log(this.route, '')
    this.getQueryCompanyIntroduce();
  },
  methods: {
    //
    async getQueryCompanyIntroduce () {
      let parm = {};
      const res = await queryCompanyIntroduce();
      this.foots = res.response
    },
  }
}
</script>
<style lang='scss' scoped>
.footer-content {
  width: 1200px;
  margin: 0 auto;
  min-height: 648px;
  h5 {
    text-align: center;
    margin: 20px 0;
  }
  .footer-font {
    font-size: 12px;
  }
}
</style>